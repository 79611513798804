@import '../variables.scss';

.select-locker {
  margin: 0 1rem;

  .search-input-component {
    margin-top: -0.4rem;
  }

  .heading {
    font-weight: 600;
    font-size: 1.18rem;
    color: $gray9;
    margin: 1.3rem 0;
  }

  .size {
    background-color: $baseWhite;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 25px;
    border-radius: 14px;
    color: $dark;
    margin-bottom: 1rem;
    font-weight: 500;

    img {
      width: 7.78px;
      height: 12.73px;
    }

    .lockers-count {
      font-size: 0.8rem;
      font-weight: normal;
    }
  }
  .w-100 {
    width: 100% !important;
  }
  .d-none {
    display: none;
  }
  .select-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .location {
      width: 49%;
    }
    .locker-tags {
      width: 49%;
    }
  }
  .sizes-dropdown > div:nth-of-type(1) {
    background-color: $baseWhite;
    border-radius: 12px;
    padding: 0.3rem;
    border: 1px solid $gray2;
    margin-top: 1rem;
    color: $gray9;
    font-size: 0.875rem;
    font-weight: 600;
    height: 45px;

    .css-14el2xx-placeholder {
      color: $gray9;
      font-weight: 500;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    svg {
      color: $gray9;
      width: 17px;
      height: 17px;
    }
  }

  .locker-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1.25rem 2.5rem;
    background: $baseWhite;
    border-radius: 12px;
    margin: 1.5rem 1rem;
    height: 130px;

    @media only screen and (max-height: 780px) {
      margin-top: 90px;
    }
    @media only screen and (min-height: 780px) {
      margin-top: 150px;
    }

    .locker-size {
      font-size: 0.813rem;
      font-weight: 500;
      color: $gray6;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .locker-location {
      font-size: 0.813rem;
      font-weight: 500;
      color: $gray6;
      margin-top: 10px;
    }

    .locker-number {
      font-size: 3rem;
      font-weight: 600;
      margin-top: 0.4rem;
    }

    .mr-2 {
      margin-right: 0.5rem;
    }
  }
  .auto-allocation-note {
    font-size: 0.813rem;
    font-weight: 400;
    text-align: center;
    color: $gray6;
    padding: 0 2.5rem 1.25rem 2.5rem;
  }

  .confirm-button-container {
    margin: auto;
    width: 70%;
    @media only screen and (max-height: 780px) {
      margin-top: 60px;
    }
    @media only screen and (min-height: 780px) {
      margin-top: 100px;
    }
  }

  .error-page-cont {
    height: calc(98vh - 67px - 8rem);

    .network-issue-icon {
      width: 100%;
      margin-bottom: -3.5%;
    }

    .heading {
      margin-top: 0rem;
    }
  }
}
