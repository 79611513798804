@import '../variables.scss';

.cancel-verified-order {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $baseWhite;
  border-radius: 14px;
  color: $gray9;
  margin: 1rem;
  padding: 2rem 1.7rem;

  .heading {
    margin: 2rem 0 5rem;
    padding: 0 2rem;
    font-weight: 600;
    color: $gray7;
    font-size: 1.25rem;
    text-align: center;
  }

  .gray-button-cont {
    margin-top: 1rem;
  }
}
