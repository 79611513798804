@import '../variables.scss';

.open-locker {
  .locker-info {
    margin: 1rem;
    background-color: $baseWhite;
    border-radius: 14px;
    color: $gray9;
    padding: 2rem 2.2rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .locker-num {
      font-size: 43px;
      font-weight: 600;
      text-align: center;
    }

    .locker {
      text-align: center;
      color: $gray6;
    }
  }

  .confirm-button {
    margin: 2rem auto 0;
    width: calc(100% - 2rem);
  }
}
.timer-instructions {
  margin-bottom: 20px;
  text-align: center;
  font-size: 16px;
  color: #868e96;
  font-weight: 300;
}

.open-next-provide-feedback {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  color: #868e96;
  font-weight: 300;
}

.contact-us-button {
  background-color: transparent;
  color: $blue;
  font-weight: 700;
  gap: 8px;
  margin-left: 1rem;
  margin-top: -10px;
  padding: 10px 20px 10px 20px;
  width: auto;
}
