@import './variables.scss';

.open-locker-animation {
  display: flex;
  flex-direction: column;
  align-items: center;

  .locker-icon {
    width: 220px;
    height: 220px;
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
      width: 74%;
      height: 74%;
      border-radius: 50%;
      z-index: 1;
    }

    .forward-effect {
      -webkit-animation: pop-swirl 1s ease forwards;
      animation: pop-swirl 1s ease forwards;
    }

    .img-background {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 87%;
      height: 87%;
      border-radius: 50%;
      opacity: 0.2;
    }

    .heartbeat-effect-with-translate {
      -webkit-animation: pop-swirl-with-translate 1.5s ease infinite;
      animation: pop-swirl-with-translate 1.5s ease infinite;
    }

    .img-background-with-opacity {
      position: absolute;
      top: 0;
      left: 0;
      background-color: $blue;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      opacity: 0.05;
    }

    .heartbeat-effect {
      -webkit-animation: pop-swirl 1.5s ease infinite;
      animation: pop-swirl 1.5s ease infinite;
    }

    .blue-background {
      background-color: $blue;
    }

    .green-background {
      background-color: $green6;
    }

    .my-6 {
      margin: 6% 0 -6%;
      max-width: 85%;
      max-height: 85%;
    }

    @keyframes pop-swirl {
      0% {
        transform: scale(0.7);
      }
      100% {
        transform: scale(1);
      }
    }

    @keyframes pop-swirl-with-translate {
      0% {
        transform: translate(-50%, -50%) scale(0.9);
      }
      100% {
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }

  .unlock-text {
    color: $gray6;
    font-weight: 600;
    font-size: 20px;
  }

  .mb-2 {
    margin-bottom: 1rem;
  }

  .mb-4 {
    margin-bottom: 1rem;
  }

  .mb-6 {
    margin-bottom: 1.5rem;
  }

  .opacity-3 {
    opacity: 0.3;
  }

  .massage-cont {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.2rem 1rem;
    margin-top: 2rem;
    background-color: $red0;
    border: 1px solid $red2;
    border-radius: 12px;

    img {
      width: 24px;
      margin-right: 0.5rem;
    }

    .message {
      color: $red7;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .error-message {
    background-color: $red0;
    border: 1px solid $red2;

    .message {
      color: $red7;
    }
  }

  .info-message {
    background-color: $yellow0;
    border: 1px solid $yellow2;
    margin-top: 0rem;

    .message {
      color: $yellow7;
    }
  }

  .circle-wrap {
    width: 192px;
    height: 192px;
    border-radius: 50%;
    margin: 2.4rem 0;
  }

  .circle-wrap .circle .mask,
  .circle-wrap .circle .fill {
    width: 191px;
    height: 191px;
    position: absolute;
    border-radius: 50%;
  }

  .circle-wrap .circle .mask {
    clip: rect(0px, 192px, 192px, 95.7px);
  }

  .circle-wrap .inside-circle {
    width: 184px;
    height: 184px;
    position: absolute;
    margin-top: 3.5px;
    margin-left: 3.5px;
    background: $baseWhite;
    border-radius: 50%;
    z-index: 100;

    img {
      margin: 5.765%;
      border-radius: 50%;
    }
  }

  .mask .fill {
    clip: rect(0px, 95.7px, 192px, 0px);
    background: $blue;
  }

  .mask.full,
  .circle .fill {
    animation: fill linear 3s infinite;
    transform: rotate(180deg);
  }

  @keyframes fill {
    0% {
      transform: rotate(0deg);
    }
    95% {
      transform: rotate(180deg);
    }
  }
}
