@import '../variables.scss';

.view-orders {
  padding: 1rem;

  .orders {
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.7rem;
      margin-bottom: 0.5rem;

      .orders-num {
        font-size: 19px;
        font-weight: 600;
      }

      .dropdown-cont {
        display: inline-flex;
        align-items: center;
        position: relative;
        background-color: $baseWhite;
        border-radius: 10px;
        padding: 1rem;
        gap: 2rem;

        .status-heading {
          color: $gray9;
          font-size: 14px;
          font-weight: 500;
        }

        .chevron-down-icon {
          width: 9.55px;
        }
      }
    }

    .order {
      display: flex;
      justify-content: space-between;
      margin-top: 0.7rem;
      padding: 1rem;
      border: 1px solid $gray1;
      border-radius: 8.9px;
      background-color: $baseWhite;

      .name {
        font-weight: 600;
        color: $gray9;
      }

      .description {
        font-size: 14px;
        color: $gray6;
      }

      .status {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 8px;
        height: 23px;
        gap: 7px;
        color: $gray9;
        font-weight: 500;
        font-size: 13px;
        border-radius: 60px;

        .dot {
          width: 9px;
          height: 9px;
          border-radius: 50%;
        }
      }
    }
  }
  .load-more {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
  }

  .error-page-cont {
    height: calc(98vh - 67px - 15rem);

    .network-issue-icon {
      width: 100%;
      margin-bottom: -3.5%;
    }

    .heading {
      margin-top: 0rem;
    }
  }
}
