@import '../variables.scss';

.order-feedback-info_min-h-48 {
  // min-height: 480px;
}

.order-feedback-info {
  .feedback {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 1rem;
    padding: 2.5rem 0 1.5rem;
    background: $baseWhite;
    border-radius: 12px;
    // height: calc(85vh - $headerHeight - 3.8rem);

    .content-heading {
      font-size: 26px;
      font-weight: 600;
    }

    .feedback-text {
      color: $gray5;
      font-size: 14px;
      margin: 0.2rem 0 0;
      padding: 0;
      line-height: 25px;
      width: 65%;
    }

    .mt-4 {
      margin-top: 1rem;
    }

    .mb-4 {
      margin-bottom: 1rem;
    }

    .gray6 {
      color: $gray6;
    }

    .experience {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      gap: 0.5rem;
    }

    .emoji {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 41px;
      height: 41px;
      border-radius: 100%;

      img {
        width: 26px;
        cursor: pointer;
      }
    }

    .active {
      background: $blue2;
    }

    .feedback-form {
      display: flex;
      flex-direction: column;
      margin: 2rem auto 0rem;
      border-top: 1px solid $gray2;
      padding-top: 1rem;
      width: 90%;
      text-align: left;
      height: 100%;

      .feedback-heading {
        color: $gray5;
        font-size: 1rem;
        margin: 0.2rem 0 0;
        padding: 0;
        line-height: 25px;
      }

      .feedback-input {
        outline: none;
        border: 1px solid $gray3;
        border-radius: 7px;
        width: 90%;
        padding: 0.8rem 1rem;
        margin: 0.6rem auto;
        color: $gray6;

        &::-webkit-input-placeholder {
          color: $gray10;
        }

        &:-moz-placeholder {
          /* Firefox 18- */
          color: $gray10;
        }

        &::-moz-placeholder {
          /* Firefox 19+ */
          color: $gray10;
        }

        &:-ms-input-placeholder {
          color: $gray10;
        }

        &::placeholder {
          color: $gray10;
        }
      }

      .submit-button {
        display: flex;
        margin-top: auto;
      }
    }
  }

  .coupon-code {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 1rem;
    padding: 1.2rem;
    background: $baseWhite;
    border-radius: 12px;

    .percent-sign {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22%;
      height: 80px;
      border-radius: 1rem;
      background-color: $green0;
      font-size: 30px;
      font-weight: bold;
      color: $green8;
    }

    .coupon-cont {
      width: 78%;
      height: 80px;
      border-radius: 1rem;
      background-color: $green0;
      text-align: start;

      .percentage {
        margin-top: 1rem;
        margin-left: 1rem;
        color: $gray9;
        font-weight: bolder;
        font-size: 19px;
      }

      .coupon {
        margin-left: 1rem;
        color: $green7;
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
}

.order-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1.25rem 2.5rem;
  background: $baseWhite;
  border-radius: 12px;
  margin: 1.5rem 1rem;

  img {
    width: 140px;
  }

  .order-heading {
    margin-top: 1.875rem;
    font-size: 20px;
    font-weight: 500;
    color: $baseBlack;
  }

  .order-details {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    margin-top: 1.875rem;
    color: $gray6;
    margin: 0.5rem 0 0;
    padding-bottom: 0;
  }

  .reopen-instructions {
    color: $gray5;
    font-size: 14px;
    margin: 0.2rem 0 0;
    padding: 0;
    line-height: 25px;
  }

  .mt-4 {
    margin-top: 1rem;
  }

  .gray6 {
    color: $gray6;
  }

  .reopen-button {
    margin-top: 1.875rem;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}
