@import './variables.scss';

.search-input-component {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 12px;
  padding: 0.7rem 1rem;
  background-color: $baseWhite;
  gap: 5px;
  box-sizing: border-box;

  img {
    width: 21px;
  }

  input {
    border: none;
    outline: none;
    padding: 0.5rem 0.3rem;
    color: $gray6;
    width: 100%;

    &::placeholder {
      color: $gray5;
      font-size: 14px;
    }
  }
}
