@import './variables.scss';

.error-page-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  height: calc(98vh - $headerHeight - 2rem);

  .not-found-icon {
    width: 178px;
  }

  .network-issue-icon {
    width: 203px;
  }

  .heading {
    font-size: 18px;
    font-weight: 600;
    color: $secondary600;
    margin: 2rem 0 0;
    text-align: center;
  }

  .error {
    color: $gray6;
    font-size: 14px;
    margin-bottom: 5rem;
    text-align: center;
  }

  .mt-6 {
    margin-top: 0.6rem;
  }
}
