@import 'styles/variables.scss';
@import url('https://fonts.googleapis.com/css?family=Poppins:regular,500,600,bold&subset=latin');
@import url('https://fonts.googleapis.com/css?family=Work+Sans:regular,500,600,bold&subset=latin');

:root {
  --branding-color: #00a4df; // Default value
}

.App {
  width: 100%;
  min-height: 100vh;
  font-family: 'Work Sans', serif;

  .mobile-view {
    background-color: $primary25;
    min-height: 100vh;
    position: relative;

    @media only screen and (min-width: 451px) {
      width: 450px;
      border-radius: 20px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      margin: 1rem auto;
      overflow: hidden;
    }
  }

  .opacity-5 {
    opacity: 0.5;
  }

  input,
  textarea {
    font-size: 1rem !important;
  }

  button,
  input,
  textarea {
    font-family: inherit;
  }

  .ready-to-load {
    background-color: $yellow0;

    .dot {
      background-color: $orange0;
    }
  }

  .loading {
    background-color: $yellow0;

    .dot {
      background-color: $yellow8;
    }
  }

  .ready-for-collection {
    background-color: $green10;

    .dot {
      background-color: $green9;
    }
  }

  .require-verification {
    background-color: $red3;

    .dot {
      background-color: $red8;
    }
  }

  .verified {
    background-color: $green1;

    .dot {
      background-color: $green7;
    }
  }

  .collecting {
    background-color: $blue1;

    .dot {
      background-color: $blue8;
    }
  }

  .collected {
    background-color: $gray900;

    .dot {
      background-color: $gray600;
    }
  }

  .clear-out {
    background-color: $pink-1;

    .dot {
      background-color: $pink-5;
    }
  }

  .clearing-out {
    background-color: $purple-0;

    .dot {
      background-color: $purple-4;
    }
  }

  .cancelled {
    background-color: $gray800;

    .dot {
      background-color: $gray700;
    }
  }

  .ready-to-load-dot {
    background-color: $orange0;
  }

  .loading-dot {
    background-color: $yellow8;
  }

  .ready-for-collection-dot {
    background-color: $green9;
  }

  .require-verification-dot {
    background-color: $red8;
  }

  .verified-dot {
    background-color: $green7;
  }

  .collecting-dot {
    background-color: $blue8;
  }

  .collected-dot {
    background-color: $gray600;
  }

  .clear-out-dot {
    background-color: $pink-5;
  }

  .clearing-out-dot {
    background-color: $purple-5;
  }

  .cancelled-dot {
    background-color: $gray700;
  }
}

.font-poppins {
  font-family: Poppins, serif;
}
