@import '../variables.scss';

.select-locker {
  margin: 0 1rem;

  .search-input-component {
    margin-top: -0.4rem;
  }

  .heading {
    font-weight: 600;
    font-size: 1.18rem;
    color: $gray9;
    margin: 1.3rem 0;
  }

  .size {
    background-color: $baseWhite;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 25px;
    border-radius: 14px;
    color: $dark;
    margin-bottom: 1rem;
    font-weight: 500;

    img {
      width: 7.78px;
      height: 12.73px;
    }

    .lockers-count {
      font-size: 0.8rem;
      font-weight: normal;
    }
  }
  .w-100 {
    width: 100% !important;
  }
  .d-none {
    display: none;
  }
  .select-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .location {
      width: 49%;
    }
    .locker-tags {
      width: 49%;
    }
  }
  .sizes-dropdown > div:nth-of-type(1) {
    background-color: $baseWhite;
    border-radius: 12px;
    padding: 0.3rem;
    border: 1px solid $gray2;
    margin-top: 1rem;
    color: $gray9;
    font-size: 0.875rem;
    font-weight: 600;
    height: 45px;

    .css-14el2xx-placeholder {
      color: $gray9;
      font-weight: 500;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    svg {
      color: $gray9;
      width: 17px;
      height: 17px;
    }
  }

  .locker-numbers {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 1rem;
    gap: 10px;

    .locker {
      background-color: var(--branding-color);
      border-radius: 12px;
      padding: 1rem 1rem 1.4rem;
      color: $baseWhite;

      .locker-size {
        font-size: 0.813rem;
        font-weight: 500;
      }

      .locker-number {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.7rem;
        font-weight: 600;
        margin-top: 0.4rem;

        img {
          width: 16px;
        }
      }
    }

    .active-locker {
      background-color: $dark;
    }
  }

  .error-page-cont {
    height: calc(98vh - 67px - 8rem);

    .network-issue-icon {
      width: 100%;
      margin-bottom: -3.5%;
    }

    .heading {
      margin-top: 0rem;
    }
  }
}
