@import '../variables.scss';

.create-order {
  padding: 2.5rem 1rem;

  .heading-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.3rem;
  
    .heading {
      font-weight: 500;
      font-size: 1.4rem;
      color: $gray9;
      margin-bottom: 1.3rem;
    }
    .no-margin {
      margin-bottom: 0 !important;
    }
  }

  .form {
    .label {
      margin-top: 1rem;
      display: flex;
      font-weight: 500;
      color: $dark;
    }
    .input {
      display: flex;
      width: 100%;
      border: none;
      outline: none;
      border-radius: 12px;
      padding: 1rem;
      color: $gray7;
      box-sizing: border-box;
      margin-top: 0.5rem;

      &::placeholder {
        color: $gray5;
        font-size: 14px;
      }
    }

    .age-verification-checkbox {
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 35px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      margin-top: 0.8rem;

      .label {
        display: inline-flex !important;
        margin-top: 0.15rem;
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      /* custom checkbox */
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 22px;
        width: 22px;
        background-color: $baseWhite;
        border: 1px solid $gray2;
        border-radius: 7px;
      }

      /* checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: '';
        position: absolute;
        display: none;
      }

      /* Show the checkmark when checked */
      input:checked ~ .checkmark:after {
        display: block;
      }

      /* checkmark/indicator */
      .checkmark:after {
        left: 7px;
        top: 2px;
        width: 6px;
        height: 13px;
        border: solid var(--branding-color);
        border-width: 0 2.5px 2.5px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    .order-items {
      margin-top: 2rem;
      background-color: $baseWhite;
      border-radius: 12px;
      padding: 1rem;

      .heading {
        margin-bottom: 0rem;
      }

      .items-requirement {
        color: $gray6;
        font-size: 14px;
        margin: 0.4rem 0;
      }

      .item-card {
        background-color: $gray0;
        border-radius: 12px;
        margin-bottom: 1rem;
        padding: 1rem;

        .head-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .label {
          font-weight: 700;
          color: $secondary600;
          margin-top: 0rem;
        }

        .input {
          background-color: $gray1;
          border: solid $gray3;
          padding: 0.9rem;
        }

        .border-line {
          border: 0.9px solid $gray2;
          border-radius: 10px;
          margin: 1rem 0 1rem;
        }
      }

      .add-item {
        color: var(--branding-color);
        text-decoration: underline;
        margin: 1.5rem 0 1rem;
        text-align: center;
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 2rem;

      .gray-button-cont {
        width: 40%;
        background-color: transparent;
        border: 1px solid var(--branding-color);
        color: $dark;
      }

      .primary-button-cont {
        width: 55%;
      }
    }
  }
  
}
.scan-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;

  .input-container {
    width: 80%;
    .cam-container {
      width: auto;
      height: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 15px;

      .cam {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .instruction-container {
      text-align: center;
      color: $gray6;
      font-size: 12px;
    }
    .scan-buttons-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 2rem;
  
      .gray-button-cont {
        width: 45%;
        background-color: $gray900;
      }
  
      .primary-button-cont {
        width: 45%;
      }
    }
  }

  .output-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    .margin-auto {
      margin: auto;
    }
    .image {
      width: auto;
    }
    .info-container {
      margin-top: 50px;
      height: 80px;
      .flex-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .title {
          font-size: 16px;
          font-weight: 500;
          width: 21%;
        }
        .cannot-detect {
          color: red;
        }

        .loading-text {
          font-weight: normal;
          display:inline-block;
          font-size: 16px;
          clip-path: inset(0 3ch 0 0);
          animation: l 1s steps(4) infinite;
        }
        
        @keyframes l {
          to {
            clip-path: inset(0 -1ch 0 0)
          }
        }
      }
      .tile-margin-top {
        margin-top: 10px;
      }
    }
    .output-buttons-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 50px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
  
      .gray-button-cont {
        width: 30%;
        background-color: $gray900;
      }
  
      .primary-button-cont {
        width: 30%;
      }
      .submit-button {
        width: 30%;
        background-color: $green8;
      }
    }
  }
}
