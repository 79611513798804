@import '../variables.scss';

.order-complete-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 1rem;
  padding: 2.5rem 0 1.5rem;
  background: $baseWhite;
  border-radius: 12px;

  .emoji {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 41px;
    height: 41px;
    border-radius: 100%;

    img {
      width: 26px;
      cursor: pointer;
    }
  }

  .active {
    background: $blue2;
  }

  .experience {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
    gap: 2rem;
  }

  .content-heading {
    font-size: 20px;
    font-weight: 500;
  }

  .feedback-sent-text {
    font-size: 26px;
    font-weight: 600;
  }

  .feedback-text {
    color: $gray5;
    font-size: 14px;
    margin: 0.2rem 0 0;
    padding: 0;
    line-height: 25px;
    width: 65%;
  }

  .mt-4 {
    margin-top: 1rem;
  }

  .mb-4 {
    margin-bottom: 1rem;
  }

  .gray6 {
    color: $gray6;
  }

  .mt-3 {
    margin-top: 0.625rem;
  }
}
.order-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1.25rem 2.5rem;
  background: $baseWhite;
  border-radius: 12px;
  margin: 1.5rem 1rem;

  img {
    width: 140px;
  }

  .order-heading {
    margin-top: 1.875rem;
    font-size: 20px;
    font-weight: 500;
    color: $baseBlack;
  }

  .order-details {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    margin-top: 1.875rem;
    color: $gray6;
    margin: 0.5rem 0 0;
    padding-bottom: 0;
  }

  .reopen-instructions {
    color: $gray5;
    font-size: 14px;
    margin: 0.2rem 0 0;
    padding: 0;
    line-height: 25px;
  }

  .mt-4 {
    margin-top: 1rem;
  }

  .gray6 {
    color: $gray6;
  }

  .reopen-button {
    margin-top: 1.875rem;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}
