@import '../variables.scss';

.clear-out-order-list {
  padding: 1rem;

  .card {
    margin-bottom: 0.8rem;
    background-color: $baseWhite;
    border-radius: 12px;
    padding: 0.8rem;

    .order-no {
      font-weight: 600;
      font-size: 17px;
      color: $secondary600;
    }

    .name {
      font-size: 14px;
      color: $gray6;
      margin-top: 0.2rem;
    }

    .primary-button-cont {
      margin-top: 0.8rem;
      padding: 0.8rem;

      .child {
        display: flex;
        align-items: center;
        gap: 10px;

        img {
          width: 12px;
        }
      }
    }
  }

  .error-page-cont {
    height: calc(98vh - 67px - 3rem);

    .network-issue-icon {
      width: 100%;
      margin-bottom: -3.5%;
    }

    .heading {
      margin-top: 0rem;
    }
  }

  .load-more {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
  }
}
