@import '../variables.scss';

.load-orders {
  padding: 1rem;

  .order {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $baseWhite;
    border-radius: 12px;
    padding: 0.93rem;
    margin: 0.7rem 0;
    border: 1px solid $gray1;

    .order-id {
      font-weight: 600;
      color: $gray9;
      font-size: 0.93rem;
    }

    .user-name {
      margin-top: 0.3rem;
      font-size: 0.8rem;
      color: $gray6;
    }

    .load-button {
      display: inline-flex;
      align-items: center;
      width: 90px;
      max-width: 90px;
      padding: 0.7rem 1.1rem;
      border-radius: 8px;
      font-size: 0.875rem;

      .align-center {
        display: flex;
        align-items: center;
        gap: 10px;

        img {
          width: 12px;
        }
      }
    }
  }

  .error-page-cont {
    height: calc(98vh - 67px - 8rem);

    .network-issue-icon {
      width: 100%;
      margin-bottom: -3.5%;
    }

    .heading {
      margin-top: 0rem;
    }
  }

  .load-more {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
  }
}
