@import '../variables.scss';

.age-verification {
  padding: 1rem;

  .search-input-component {
    margin-bottom: 1rem;
  }

  .card {
    margin-bottom: 0.8rem;
    background-color: $baseWhite;
    border-radius: 12px;
    padding: 1rem;

    .order-no {
      font-weight: 500;
      font-size: 17px;
      color: $secondary600;
    }

    .name {
      font-size: 14px;
      color: $gray6;
      margin-top: 0.2rem;
    }

    .button {
      margin-top: 1.1rem;

      .child {
        display: flex;
        align-items: center;
        gap: 10px;

        img {
          width: 7.78px;
          height: 12.73px;
        }
      }
    }
  }

  .error-page-cont {
    height: calc(98vh - 67px - 8rem);

    .network-issue-icon {
      width: 100%;
      margin-bottom: -3.5%;
    }

    .heading {
      margin-top: 0rem;
    }
  }

  .load-more {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
  }
}
