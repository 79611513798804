@import '../variables.scss';

.sidemenu-cont {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  overflow-x: hidden;
  background: rgba(134, 142, 150, 0.2);
  backdrop-filter: blur(2px);
  z-index: 2;

  .sidemenu {
    position: absolute;
    top: 1.5rem;
    right: 0.7rem;
    background: $baseWhite;
    box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.1);
    height: 89%;
    width: 85%;
    border-radius: 13px;
    z-index: 3;
    padding: 1.5rem 0;
    animation: appearSideMenu 350ms ease-in 1;

    .switch-account-section {
      background-color: $gray0;
      margin: 0.7rem 1.5rem 1rem 1.5rem;
      padding: 1rem;
      border: 1px solid $gray1;
      border-radius: 12px;

      .account-name {
        font-size: 1.3rem;
        font-weight: 500;
        margin-bottom: 1rem;
      }
    }

    .border-line {
      border: 0.9px solid $gray2;
      border-radius: 10px;
      margin: 1.9rem 1.5rem 1rem 1.5rem;
    }

    .header {
      padding: 0 1rem 1rem 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .menu-text {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        color: $gray5;
      }

      .close-icon {
        width: 38px;
      }
    }

    .screen {
      font-size: 20px;
      font-weight: 500;
      color: $secondary600;
      padding: 0.6rem 1.5rem;
      margin: 0.4rem 0;
    }

    .active-screen {
      border-left: 3px solid $dark;
    }
  }

  @keyframes appearSideMenu {
    0% {
      opacity: 0;
      transform: translateX(10px);
    }
  }
}
