@import '../variables.scss';

.age-verification-prompt {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow-x: hidden;
  background: rgba(134, 142, 150, 0.2);
  backdrop-filter: blur(10px);
  z-index: 2;

  .prompt {
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    background: $baseWhite;
    border-radius: 20px;
    z-index: 3;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    animation: appearPrompt 0.6s;

    .close-icon {
      margin-left: auto;
      width: 12.73px;
    }

    .verify-age-icon {
      width: 120.29px;
    }

    .heading {
      font-size: 20px;
      margin-top: 1.5rem;
      color: $baseBlack;
      font-weight: 600;
      text-align: center;
    }

    .content {
      font-size: 14px;
      text-align: center;
      color: $gray6;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }

    .fs-bold-light {
      font-weight: 600;
    }

    .blue-button {
      background-color: $blue;
    }

    .green-button {
      margin-top: 0.6rem;
      background-color: $green;
      color: $baseWhite;
    }

    .dark-button {
      margin-top: 0.6rem;
      background-color: $dark;
      color: $baseWhite;
    }
  }

  @keyframes appearPrompt {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0);
    }
    100% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }
}
