@import '../variables.scss';

.clear-out-order {
  .clear-out-order-info {
    margin: 1rem;
    background-color: $baseWhite;
    border-radius: 14px;
    color: $gray9;
    padding: 2rem 2.2rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .locker-num {
      font-size: 43px;
      font-weight: 600;
      text-align: center;
    }

    .locker {
      text-align: center;
      color: $gray6;
    }
  }

  .dark-text {
    color: $dark;
  }

  .primary-button-cont {
    margin: 1.5rem auto 0;
    width: calc(100% - 2rem);
  }
}
