@import '../variables.scss';

.update-password-form {
  padding: 2.6rem 1rem;

  .heading {
    font-size: 26px;
    font-weight: 500;
    color: $gray9;
    margin-bottom: 1.6rem;
  }

  label {
    display: flex;
    margin-top: 1rem;
    color: $dark;
    font-weight: 500;
  }

  input {
    display: flex;
    width: 100%;
    margin-top: 0.5rem;
    border: none;
    outline: none;
    border-radius: 12px;
    padding: 1rem;
    color: $gray7;
    box-sizing: border-box;
  }

  .input-password {
    display: flex;
    width: 100%;
    margin-top: 0.5rem;
    border-radius: 12px;
    align-items: center;
    background-color: $baseWhite;
    padding-right: 1rem;
    box-sizing: border-box;

    input {
      width: 100%;
      margin-top: 0rem;
      padding-right: 0.5rem;
    }
  }
  input::placeholder {
    opacity: 0.5;
  }

  .password-error {
    color: $red7;
    font-size: 0.9rem;
    margin-top: 1rem;

    ul {
      padding: 0 0 0 1rem;

      li {
        margin-top: 0.5rem;
      }
    }
  }

  .primary-button-cont {
    margin-top: 2rem;
  }
}
