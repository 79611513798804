@import '../variables.scss';

.individual-order {
  padding: 1rem;

  .top-heading {
    color: #212529;
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 20px;
  }

  .mt-4 {
    margin-top: 1rem;
  }
  .mt-1 {
    margin-top: 0.25rem;
  }

  .header {
    background-color: $baseWhite;
    border-radius: 15px;

    .space-between-2 {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      border-bottom: 1px solid $gray1;
      padding: 0.8125rem 0.9375rem;

      .title {
        font-size: 14px;
        font-weight: 500;
        color: $gray9;
      }
    }

    .order-status {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 0.8125rem 0.9375rem 0.8125rem;

      .heading {
        color: var(--branding-color);
        text-align: center;
        font-size: 22px;
        font-weight: 600;
        margin-block-start: 8px;
        margin-block-end: 8px;
      }
      .order-date {
        font-weight: 500;
        font-size: 12px;
        color: $gray6;
      }
    }
  }

  .statuses {
    display: flex;
    flex-direction: column;
    padding-block-start: 20px;

    .outter-block {
      display: flex;
      .circle-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        .circle-blue {
          width: 40px;
          height: 37px;
          border-radius: 50%;
          display: inline-block;
          background-color: var(--branding-color);
        }
        .circle-gray {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          display: inline-block;
          background-color: $baseWhite;
          border: 2px solid #C6C7C7
        }
        .dotted-line-blue {
          width: 0;
          height: 74px;
          border-left: 2px dashed var(--branding-color);
        }
        .dotted-line-gray {
          width: 0;
          height: 74px;
          border-left: 2px dashed #C6C7C7;
        }
      }

      .inner-block {
        padding-inline-start: 15px;
        .status-name {
          font-size: 16px;
          font-weight: 700;
          margin-block-start: 8px;
          margin-block-end: 8px;
          color: #414042;
        }
        .date-name {
          color: #414042;
          font-size: 12px;
          font-weight: 300;
        }
      }
    }
  }

  .updates {
    display: flex;
    justify-content: space-between;
    background-color: $baseWhite;
    border-radius: 12px;
    padding: 0.9375rem;
    margin-top: 1.1rem;

    .heading {
      color: $gray9;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .updates-block {
    background-color: $baseWhite;
    border-radius: 15px;
    padding: 0.8125rem 0.9375rem;
    margin-top: 0.5rem;
    .text {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      border-bottom: 1px solid $gray1;
      padding-bottom: 5px;
      color: $gray9;
    }

    .details {
      padding-top: 5px;
      .date {
        color: $gray9;
        font-size: 10px;
        font-weight: 500;
      }
      .name {
        color: $gray9;
        font-size: 10px;
        font-weight: 500;
        margin-inline-start: 30px;
      }
    }

  }

  .chevron-icon-cont {
    .chevron-down-icon {
      width: 12px;
      height: 12px;
    }

    .chevron-up-icon {
      width: 12px;
      height: 12px;
      transform: rotate(180deg);
    }
  }

  .expand-container {
    overflow: hidden;
    transition: height 0.4s ease;
    height: 0;
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  padding-left: 1rem;
  padding-right: 1rem;

  .open-locker {
    width: 40%;
    background-color: var(--branding-color);
  }

  .cancel-order {
    width: 55%;
    margin-left: auto;
    margin-right: auto;
    background-color: red;
  }
}

.w-55 {
  width: 55% !important;
  margin-left: auto;
  margin-right: auto;
}
