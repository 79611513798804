@import '../variables.scss';

.switch-account {
  padding: 2.5rem 1rem;

  .heading {
    font-weight: 500;
    font-size: 1.4rem;
    color: $gray9;
    margin-bottom: 1.1rem;
  }

  .disabled {
    opacity: 0.75;
  }

  .account-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $baseWhite;
    padding: 1.2rem 1rem;
    margin-bottom: 0.8rem;
    border-radius: 12px;

    .name {
      color: $secondary600;
    }

    .radio-button {
      -webkit-appearance: none;
      width: 11px;
      height: 11px;
      border-radius: 100%;
      border: 1px solid $gray2;
    }
  }

  .selected-account {
    .name {
      font-weight: 600;
    }

    .radio-button {
      background-color: var(--branding-color);
      border: 1px solid var(--branding-color);
    }
  }
}
