@import '../variables.scss';

.age-verification-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 1rem;
  padding: 6rem 2rem 5rem;
  background: $baseWhite;
  border-radius: 12px;

  .img {
    width: 151px;
  }

  .content-heading {
    font-size: 18.5px;
    font-weight: 600;
    margin-top: 2rem;
  }
  .content-details {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: $gray6;
  }
}
