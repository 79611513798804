.custom-single-value{
  display: flex;
  align-items: center;
}

.location-img { 
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.custom-option {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}
