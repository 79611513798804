@import './variables.scss';

.gray-button-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: $gray2;
  color: $gray7;
  border-radius: 12px;
  font-weight: 500;
  font-size: 15px;
  border: none;
  cursor: pointer;
}
