@import '../variables.scss';

.contact-us {
  padding: 1rem 1.3rem;

  .heading {
    font-weight: 700;
    font-size: 20px;
    color: $gray9;
    margin-bottom: 1.3rem;
    margin: 1rem 0;
  }

  .card {
    display: flex;
    align-items: flex-start;
    padding: 15px;
    gap: 20px;
    background-color: $baseWhite;
    border-radius: 8px;
    margin-bottom: 10px;


    .time-sheet {
      display: grid;
      grid-template-columns: repeat(2, 3fr);
      grid-template-rows: repeat(7, 1fr);
      grid-column-gap: 50px;
      grid-row-gap: 20px;
      padding-top: 5px;
      margin-bottom: 8px ;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .title {
      color: $gray9;
      margin-bottom: 2px;
    }

    .title-bold {
      color: $gray9;
      font-weight: 600;
    }

    .info {
      color: $blue;
      font-weight: 600;
    }
  }
}
