@import './variables.scss';

.confirm-button-auto-allocate {
  position: relative;
  width: 100%;
  background-color: var(--branding-color);
  color: $baseWhite;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  overflow: hidden;
  transition: background-color 0.3s ease;
  z-index: 1;
  cursor: pointer;

  &:hover {
    background-color: var(--branding-color);
    filter: brightness(1.2);
  }

  .progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: var(--branding-color);
    filter: brightness(0.5);
    transition: width 0.1s linear;
    z-index: -1;
  }

  span {
    z-index: 1;
    position: relative;
  }
}
