@import '../variables.scss';

.store-name-cont {
  width: 100%;

  .store-info {
    margin: 1rem;
    background-color: $baseWhite;
    border-radius: 14px;
    color: $gray9;
    padding: 2rem 1.7rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .locker-num {
      font-size: 47px;
      font-weight: 600;
      text-align: center;
    }

    .locker {
      text-align: center;
      color: $gray6;
    }

    .name {
      padding-top: 2.4rem;
      color: var(--branding-color);
      font-weight: 500;
    }

    .store-name-text {
      margin-top: 0.3rem;
      margin-bottom: 2.2rem;
      font-weight: 600;
    }

    .primary-button img {
      width: 18px;
      margin-right: 0.5rem;
    }

    .store-name-buttons-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .yes-button {
        width: 48%;
      }
      .cancel-button {
        width: 48%;
      }
    }

    .instruction-container {
      margin-top: 2rem;
      text-align: center;
      color: $gray6;
      font-size: 12px;
    }
  }
}

.timer-instructions {
  margin-bottom: 20px;
  text-align: center;
  font-size: 16px;
  color: #868e96;
  font-weight: 300;
}

.help_gif {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $gray9;
  background-color: $baseWhite;
  border-radius: 14px;
  padding: 2rem 1.7rem;
  margin: 1rem;

  .image {
    width: 200px;
    height: 200px;
    cursor: pointer;
  }
}
