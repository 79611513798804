@import '../variables.scss';

.order-cancelled-info {
  .error-page-cont {
    height: calc(98vh - 67px - 1rem);

    .network-issue-icon {
      width: 170px;
    }

    .heading {
      font-size: 1.3rem;
    }
  }
}
