@import '../variables.scss';

.order-collected-info {
  .error-page-cont {
    .network-issue-icon {
      width: 230px;
    }

    .heading {
      margin-top: 0.3rem;
    }
  }
}
