$baseWhite: #ffffff;
$baseBlack: #000;
$gray0: #f8f9fa;
$gray1: #f1f3f5;
$gray2: #e9ecef;
$gray3: #e5e9f2;
$gray4: #ced4da;
$gray5: #adb5bd;
$gray6: #868e96;
$gray7: #495057;
$gray8: #343a40;
$gray9: #212529;
$gray10: #c0ccda;
$gray25: #fcfcfd;
$gray50: #f9fafb;
$gray300: #d0d5dd;
$gray400: #98a2b3;
$gray500: #667085;
$gray600: #B3B3B3;
$gray700: #4E4E4E;
$gray800: #909090;
$gray900: #CFD1D2;
$primary15: #f5f9fe;
$primary25: #f8f9fc;
$primary50: #e0f4fe;
$primary100: #b0e3fb;
$primary200: #7bd0f9;
$primary300: #43bef5;
$primary400: #04b2f4;
$primary500: #00a2f1;
$primary600: #0094e3;
$primary700: #0082cf;
$blue: #00a4df;
$blue1: #d0ebff;
$blue2: #e1ebff;
$blue7: #1c7ed6;
$blue8: #3498FF;
$pink-0: #ffdaf9;
$pink-1: #FFD0E9;
$pink-4: #f863e4;
$pink-5: #ff66ff;
$purple-0: #e1d5e7;
$purple-4: #9673a6;
$purple-5: #9933FF;
$secondaryBlue: #37517e;
$secondaryMid: #2e4369;
$secondaryDark: #253655;
$secondary600: #1c2d41;
$headerHeight: 72px;
$red0: #fff5f5;
$red1: #ffe5df;
$red2: #ffc9c9;
$red3: #FFD9D0;
$red5: #ff5733;
$red7: #f03e3e;
$red8: #FF3333;
$yellow0: #fff9db;
$yellow2: #ffec99;
$yellow7: #f59f00;
$yellow8: #F0F100;
$dark: #001f3c;
$green0: #ebfbee;
$green1: #d3f9d8;
$green6: #40c057;
$green7: #37b24d;
$green8: #2f9e44;
$green: #43c172;
$green9: #00CC00;
$green10: #DFFEC1;
$orange0: #EB9443
