@import '../variables.scss';

.login-form {
  padding: 1rem;
  font-size: 14px;

  label {
    display: flex;
    margin-top: 1rem;
    color: $dark;
    font-weight: 500;
    font-size: 1rem;
  }

  input:not([type='checkbox']) {
    display: flex;
    width: 100%;
    margin-top: 0.5rem;
    border: none;
    outline: none;
    border-radius: 12px;
    padding: 1rem;
    color: $gray7;
    box-sizing: border-box;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 40rem #ffff inset;
  }

  .input-password {
    display: flex;
    width: 100%;
    margin-top: 0.5rem;
    border-radius: 12px;
    align-items: center;
    background-color: $baseWhite;
    padding-right: 1rem;
    box-sizing: border-box;

    input {
      width: 100%;
      margin-top: 0rem;
      padding-right: 0.5rem;
    }
  }

  input::placeholder {
    opacity: 0.5;
  }

  .remember-me-cont {
    display: flex;
    align-items: center;
    margin-top: 0.8rem;

    .remember-me {
      color: $dark;
      line-height: 16px;
      margin-left: 0.3rem;
      letter-spacing: -0.02em;
    }

    .forgot-password {
      margin-left: auto;
      color: $dark;
      font-weight: 600;
      line-height: 16px;
      text-align: right;
      letter-spacing: -0.02em;
      cursor: pointer;
    }
  }

  .primary-button-cont {
    margin-top: 1.5rem;
  }
}
