@import '../variables.scss';

.lockers {
  margin: 0 1rem;
  .search-input-component {
    margin-top: 0.5rem;
  }

  .w-100 {
    width: 100% !important;
  }
  .d-none {
    display: none;
  }
  .select-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .location {
      width: 49%;
    }
    .locker-tags {
      width: 49%;
    }
  }

  .sizes-dropdown > div:nth-of-type(1) {
    background-color: $baseWhite;
    border-radius: 12px;
    padding: 0.3rem;
    border: 1px solid $gray2;
    margin-top: 1rem;
    color: $gray9;
    font-size: 0.875rem;
    font-weight: 600;
    height: 45px;

    .css-14el2xx-placeholder {
      color: $gray9;
      font-weight: 500;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    svg {
      color: $gray9;
      width: 17px;
      height: 17px;
    }
  }

  .locker {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $baseWhite;
    border-radius: 12px;
    padding: 0.93rem;
    margin: 0.7rem 0;
    border: 1px solid $gray1;

    .left-container {
      display: flex;
      justify-content: center;
      align-items: center;
      .locker-id {
        font-weight: 600;
        color: $gray9;
        font-size: 0.93rem;
        padding-left: 0.25rem;
      }
    }

    .locker-name {
      margin-top: 0.3rem;
      font-size: 0.8rem;
      color: $gray6;
    }

    .load-button {
      display: inline-flex;
      align-items: center;
      width: auto;
      padding: 0.7rem 1.1rem;
      border-radius: 8px;
      font-size: 0.875rem;

      .align-center {
        display: flex;
        align-items: center;
        gap: 10px;

        img {
          width: 12px;
        }
      }
    }
    .status {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 8px;
      height: 23px;
      gap: 7px;
      color: $gray9;
      font-weight: 500;
      font-size: 13px;
      border-radius: 60px;

      .dot {
        width: 9px;
        height: 9px;
        border-radius: 50%;
      }
    }
    .order_details {
      display: flex;
      flex-direction: column;
      align-items: center;

      .bottom-container {
        padding-top: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        .order_no {
          color: $gray6;
          font-size: 14px;
          font-weight: 500;
          padding-left: 0.25rem;
        }
      }
    }
  }
  .error-page-cont {
    height: calc(98vh - 67px - 8rem);

    .network-issue-icon {
      width: 100%;
      margin-bottom: -3.5%;
    }

    .heading {
      margin-top: 0rem;
    }
  }

  .load-more {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
  }
}
