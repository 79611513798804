@import '../variables.scss';

.status-dropdown {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow-x: hidden;
  background: rgba(134, 142, 150, 0.2);
  backdrop-filter: blur(2px);
  z-index: 2;

  .dropdown {
    position: absolute;
    top: 13rem;
    right: 1rem;
    width: 180px;
    background: $baseWhite;
    border-radius: 10px;
    z-index: 3;
    padding: 1.5rem 1.5rem 1rem;
    font-size: 1.1rem;
    color: $gray9;

    animation: appearDropDown 350ms ease-in 1;

    .d-flex {
      display: flex;
      align-items: center;
    }

    .justify-between {
      justify-content: space-between;
    }

    .checkmark {
      display: inline-block;
      width: 22px;
      height: 22px;
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);

      .checkmark_stem {
        position: absolute;
        width: 2px;
        height: 14px;
        background-color: var(--branding-color);
        left: 11px;
        top: 4px;
      }

      .checkmark_kick {
        position: absolute;
        width: 6px;
        height: 2px;
        background-color: var(--branding-color);
        left: 5px;
        top: 16px;
      }
    }

    .bold-medium {
      font-weight: 600;
    }

    .gap-12 {
      gap: 12px;
    }

    .mb-5 {
      margin-bottom: 1.25rem;
    }

    .mt-4 {
      margin-top: 1rem;
    }

    .dot {
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }

    .dropdown-status {
      width: calc(100% - 1.5rem);
    }
  }

  @keyframes appearDropDown {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
  }
  .half-opacity {
    opacity: 0.4;
  }
}
