@import './variables.scss';

.header-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: $baseWhite;
  height: $headerHeight;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  position: relative;

  .icon-space {
    flex: 0 0 18px;
    width: 18px;
    height: 18px;
  }

  .back-icon {
    width: 18px;
  }

  .logo {
    max-width: 180px;
    max-height: 50px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .menu-icon {
    width: 18px;
  }
}

.header-dark-cont {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: $headerHeight;

  .back-icon {
    width: 16px;
  }

  .logo {
    margin: 0 auto;
  }
}
