@import '../variables.scss';

.dashboard-cont {
  padding: 1rem 1.3rem;

  .card {
    display: flex;
    align-items: center;
    padding: 15px;
    gap: 9px;
    background-color: $baseWhite;
    border-radius: 8px;
    margin-bottom: 1rem;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $gray1;
      border-radius: 10px;
      padding: 15px;

      img {
        width: 40px;
      }

      svg {
        width: 40px;
        height: 40px;
      }

      .clear-out-icon path {
        stroke-width: 12px;
        stroke: white;
        fill: #011f3c;
      }
    }

    .screen-name {
      font-size: 18px;
      font-weight: 600;
      margin-left: 0.5rem;
    }
  }
}
