@import '../variables.scss';

.tutorial_video {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  color: $gray9;
  background-color: $baseWhite;
  border-radius: 14px;
  margin: 1rem;
  padding: 2rem 1.7rem;
}
