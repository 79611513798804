@import './variables.scss';

.app-layout {
  padding-bottom: 2rem;

  .user-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $gray9;
    padding: 1rem 0.7rem;
    margin: 1rem;
    border-radius: 10px;
    background-color: $baseWhite;

    .code,
    .name {
      display: flex;
      align-items: center;
      font-size: 14px;
      width: 50%;
      max-width: 50%;

      img {
        margin-right: 0.6rem;
        width: 12px;
      }
    }

    .code-text, .name-text{
      width: 100%;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .code {
      justify-content: end;
      width: 45%;
      max-width: 45%;
      flex-basis: fit-content;
    }

    .name {
      font-weight: 500;
    }
  }
}
