@import '../variables.scss';

.forgot-password-form {
  padding: 2.6rem 1rem;
  font-size: 14px;

  .heading {
    font-size: 22px;
    font-weight: 500;
    color: $gray9;
  }

  label {
    display: flex;
    margin-top: 1.6rem;
    color: $dark;
    font-weight: 500;
    font-size: 1rem;
  }

  input {
    display: flex;
    width: 100%;
    margin-top: 1rem;
    border: none;
    outline: none;
    border-radius: 12px;
    padding: 1rem;
    color: $gray7;
    box-sizing: border-box;
  }

  input::placeholder {
    opacity: 0.5;
  }

  .primary-button-cont {
    margin-top: 1.5rem;
  }
}
