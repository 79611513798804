@import '../variables.scss';

.scan-qr-cont {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  justify-content: space-between;
  background-color: $baseBlack;
  color: $baseWhite;
  text-align: center;
  overflow: hidden;
  position: relative;

  .scanner-container {
    position: relative;
    width: 75%;
    margin: 0 auto;
    height: 300px;

    .scanner {
      width: 100%;
      height: 100%;

      video {
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
        overflow: hidden;
      }
    }
  }

  .scanner-container-border::before {
    content: '';
    position: absolute;
    width: 60px;
    height: 60px;
    left: -7px;
    top: -7px;

    border-bottom: 7px solid #ffffff;
    border-right: 7px solid #ffffff;
    border-radius: 30px 4px;
    transform: rotate(-180deg);
    z-index: 2;
  }

  .scanner-container-border::after {
    content: '';
    position: absolute;
    width: 60px;
    height: 60px;
    right: -7px;
    top: -7px;

    border-bottom: 7px solid #ffffff;
    border-left: 7px solid #ffffff;
    border-radius: 4px 30px;
    transform: rotate(-180deg);
    z-index: 2;
  }

  .scanner-border::before {
    content: '';
    position: absolute;
    width: 60px;
    height: 60px;
    left: -7px;
    bottom: -7px;

    border-top: 7px solid #ffffff;
    border-right: 7px solid #ffffff;
    border-radius: 4px 30px;
    transform: rotate(-180deg);
    z-index: 2;
  }

  .scanner-border::after {
    content: '';
    position: absolute;
    width: 60px;
    height: 60px;
    right: -7px;
    bottom: -7px;

    border-top: 7px solid #ffffff;
    border-left: 7px solid #ffffff;
    border-radius: 30px 4px;
    transform: rotate(-180deg);
    z-index: 2;
  }

  .heading {
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 6rem;
  }
}
